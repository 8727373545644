import { useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { AxiosResponse, AxiosError } from 'axios'
import { useSettings } from '../../hooks'
import { PATH } from '../../constants/global'
import { useAPI } from '../'
import { APIError } from '../types'
import { getAPIErrorMessage } from '../../utils/functions'

interface UseSettingsWizard {
   isWizardError: boolean
   settingsUploading: boolean
   isWizardSuccess: boolean
   refresh: () => void
   error: string
   updateWizardSettings: (wizardSettings: WizardSettings) => void
}

type WizardSettings = {
   LocationTracking: boolean
   QuantityItems: boolean
   KitItems: boolean
   Consumables: boolean
   Audits: boolean
   GlobalScanToAccept: boolean
}

export default function (): UseSettingsWizard {
   const [error, setErrors] = useState('')
   const request = useAPI()
   const { refresh } = useSettings()

   const {
      isLoading: settingsUploading,
      isSuccess: isWizardSuccess,
      mutate,
      isError: isWizardError,
   } = useMutation(updateSystemSettingsWithWizard, {
      onSuccess: () => {
         refresh()
      },
      onError: (error: APIError) => {
         const msg = getAPIErrorMessage(error)
         setErrors(msg)
      },
   })

   async function updateSystemSettingsWithWizard(
      settings: WizardSettings
   ): Promise<AxiosResponse> {
      return await request.post(PATH.SETTINGS_WIZARD.SET_SETTINGS, {
         ...settings,
      })
   }

   const updateWizardSettings = (settings: WizardSettings) => {
      setErrors('')
      mutate(settings)
   }

   return {
      isWizardError,
      settingsUploading,
      isWizardSuccess,
      refresh,
      error,
      updateWizardSettings,
   }
}
