import React, { memo, useCallback, useMemo } from 'react'
import { SlidePanel } from '..'
import ItemReorder from '../itemReorder/itemReorder'

const ColumnReorderPanel = ({
   isColumnSettingsOpen,
   setIsColumnSettingsOpen,
   handleResetColumns,
   handleSaveColumnReorder,
   handleReorderColumns,
   initialColumnItems,
   currentColumnItems,
   tableOptions,
}: ColumnReorderPanelProps) => {
   const handleClose = useCallback(
      () => setIsColumnSettingsOpen(false),
      [setIsColumnSettingsOpen]
   )

   const items = useMemo(
      () =>
         tableOptions?.length > 0 ? currentColumnItems : initialColumnItems,
      [tableOptions, currentColumnItems, initialColumnItems]
   )

   return (
      <SlidePanel
         variant="Table Columns"
         isOpen={isColumnSettingsOpen}
         onClose={handleClose}
         onReset={handleResetColumns}
         onSave={handleSaveColumnReorder}
      >
         <p>
            Add or remove columns. To change the column order, drag and drop a
            field.
         </p>
         <ItemReorder
            initialItems={initialColumnItems}
            items={items}
            onChange={handleReorderColumns}
         />
      </SlidePanel>
   )
}

export default memo(ColumnReorderPanel)
