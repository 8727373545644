import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { APIError } from '../hooks/types'
import { formatExcelFileNameDate } from './datetime'

const sortObjectsByAlpha = (array, objectProp) => {
   array.sort(function (a, b) {
      if (a[objectProp] < b[objectProp]) {
         return -1
      }
      if (a[objectProp] > b[objectProp]) {
         return 1
      }
      return 0
   })
   return array
}

const truncate = (input: string, max: number = 10) => {
   if (input && input.length > max) {
      return input.substring(0, max) + '...'
   }
   return input
}

const truncateMiddle = (input: string, max: number = 30) => {
   if (input && input.length > max) {
      return (
         input.substring(0, max - 9) +
         '...' +
         input.substring(input.length - 9, input.length)
      )
   }
   return input
}

const capitalize = (str: string) => {
   const lower = str.toLowerCase()
   return str.charAt(0).toUpperCase() + lower.slice(1)
}

const delayedReset = (cb: Function, timeout: number = 3000) => {
   setTimeout(() => {
      cb()
   }, timeout)
}

const currencyFormatter = (currency: number) => {
   const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
   })

   return formatter.format(currency)
}

const formatPrice = (str?: string): string => {
   const num = Number(str)
   if (!num || isNaN(num) || num === 0) {
      return '-'
   }
   const isWholeNumber = num % 1 === 0
   let price =
      new Intl.NumberFormat('en-US', {
         style: 'currency',
         currency: 'USD',
         minimumFractionDigits: isWholeNumber ? 0 : 2,
      }).format(num) ?? '-'

   return isWholeNumber ? `${price}.00` : price
}

const statusColourScheme = (status: string) => {
   return {
      availableStatus: status === 'Available',
      loanedStatus: status === 'Loaned',
      pendingStatus: status === 'Pending',
      unavailableStatus: status === 'Unavailable',
      defaultStatus: status === 'default',
      infoStatus: status === 'info',
   }
}

const statusDescIdScheme = (StatusDesc: string) => {
   switch (StatusDesc) {
      case 'Available':
         return 1
      case 'Unavailable':
         return 2
      case 'Loaned':
         return 4
      case 'Borrowed':
         return 8
      case 'Pending':
         return 10
      default:
         return null
   }
}

const buildAddress = (iAddress: any) => {
   let set_address = ''
   if (iAddress) {
      if (iAddress.line1) {
         set_address += iAddress.line1 + ', '
      }
      if (iAddress.line2) {
         set_address += iAddress.line2 + ', '
      }
      if (iAddress.line3) {
         set_address += iAddress.line3 + ', '
      }
      if (iAddress.city) {
         set_address += iAddress.city + ', '
      }
      if (iAddress.county) {
         set_address += iAddress.county + ', '
      }

      if (iAddress.country) {
         set_address += iAddress.country + ', '
      }
      if (iAddress.postCode) {
         set_address += iAddress.postCode
      }
   } else {
      set_address = 'Address not available'
   }
   return set_address
}

const isEmptyObject = (obj?: object) => {
   if (!obj) return false

   return !Object.getOwnPropertyNames(obj).length
}

const populateArray = (
   min: number,
   max: number,
   incriment: number,
   stopAtNumber: number
) => {
   let arr = []
   for (let i = min; i <= max; i += incriment) {
      if (i >= stopAtNumber) {
         break
      }
      arr.push(i)
   }
   return arr
}

// This const disables keyboard input
const disableKeyboardInput = () => {
   window.addEventListener('keydown', preventDefault)
   window.addEventListener('keypress', preventDefault)
   window.addEventListener('keyup', preventDefault)
}

// This const re-enables keyboard input
const enableKeyboardInput = () => {
   window.removeEventListener('keydown', preventDefault)
   window.removeEventListener('keypress', preventDefault)
   window.removeEventListener('keyup', preventDefault)
}

// This const prevents the default behavior of keyboard events
const preventDefault = (event) => {
   event.preventDefault()
}

const isBrowser = () => typeof window !== 'undefined'

const objectsAreEqual = <T extends object>(obj1: T, obj2: T) => {
   const keys1 = Object.keys(obj1)
   const keys2 = Object.keys(obj2)

   if (keys1.length !== keys2.length) {
      return false
   }

   for (const key of keys1) {
      if (obj1[key as keyof T] !== obj2[key as keyof T]) {
         return false
      }
   }

   return true
}

const randomString = () => Math.random().toString(36).slice(2)

const getAPIErrorMessage = (error: APIError): string => {
   if (error?.response?.data?.ModelState) {
      return Object.values(error.response.data.ModelState)?.flat()[0]
   } else if (error?.response?.data?.Message) {
      return error.response.data.Message
   } else if (error?.response?.data?.message) {
      return error.response.data.message
   } else {
      return 'There was an unknown server error.'
   }
}

const exportToExcel = async (excelData: any[], customFileName?: string) => {
   const fileExtension = '.xlsx'
   const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
   const fileName = customFileName
      ? `${customFileName}${fileExtension}`
      : `Cart Export - ${formatExcelFileNameDate(new Date())}${fileExtension}`
   const worksheet = XLSX.utils.json_to_sheet(excelData)
   const numHeaders = Object.keys(excelData[0]).length
   worksheet['!cols'] = Array(numHeaders).fill({ wch: 20 })
   const headerKeys = Object.keys(worksheet).filter((key) =>
      /^[A-Z]+[1]$/.test(key)
   )

   headerKeys.forEach(
      (key) =>
         (worksheet[key].s = {
            fill: {
               fgColor: { rgb: 'FFD1D7DB' },
            },
            alignment: {
               vertical: 'center',
               horizontal: 'center',
               wrapText: '1',
            },
            border: {
               right: {
                  style: 'thick',
                  color: '96999B',
               },
               left: {
                  style: 'thick',
                  color: '96999B',
               },
               top: {
                  style: 'thick',
                  color: '96999B',
               },
               bottom: {
                  style: 'thick',
                  color: '96999B',
               },
            },
         })
   )

   const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
   const data = new Blob([excelBuffer], { type: fileType })
   FileSaver.saveAs(data, fileName)
}

export {
   sortObjectsByAlpha,
   truncate,
   truncateMiddle,
   capitalize,
   delayedReset,
   currencyFormatter,
   statusColourScheme,
   statusDescIdScheme,
   buildAddress,
   isEmptyObject,
   populateArray,
   disableKeyboardInput,
   enableKeyboardInput,
   preventDefault,
   isBrowser,
   objectsAreEqual,
   randomString,
   getAPIErrorMessage,
   exportToExcel,
   formatPrice,
}
