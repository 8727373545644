import React, { memo } from 'react'
import { CsvInnerButtonProps } from './types'
import * as styles from './csvDownloadForm.module.scss'
import Svg from '../svg/svg'

const CsvInnerButton = ({
   height,
   id,
   overrideViewBox,
   text,
   width,
}: CsvInnerButtonProps) => (
   <div className={styles.innerButton}>
      <Svg
         id={id}
         height={height}
         width={width}
         overrideViewBox={overrideViewBox}
      />
      <span>{text}</span>
   </div>
)

export default memo(CsvInnerButton)
