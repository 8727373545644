export enum GPSScanEvents {
   BorrowToolRequest = 10, //
   LoanToolRequest = 20, //
   ReturnRequest = 30, //
   TransferToolToRequest = 40,
   AuditScan = 1006, //
   BarcodeScan = 1005, //
   TransferToolFromRequest = 50, //
   AcceptedBorrowToolRequest = 11, //
   AcceptedLoanToolRequest = 21, //
   AcceptedReturnRequest = 31, //
   ToolNoteCreated = 1002, //
   LocationTransferred = 1100, //
   UpdateItemUnavailable = 1101, //
   AddedTokit = 1102, //
   RemovedFromKit = 1103, //
   UpdateItemAvailable = 1104, //
   WarehouseTransfer = 1105, //
   AcceptedTransferToolToRequest = 1106, //
   AcceptedTransferToolFromRequest = 1107, //
}
