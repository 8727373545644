import React, { useState, useEffect, memo, useMemo } from 'react'
import Button from '../button/button'
import { RadioButtonContainer, RadioButton, CsvAnchor, Svg } from '../../common'
import * as styles from './csvDownloadForm.module.scss'
import { ImSpinner4 as Spinner } from 'react-icons/im'
import * as buttonStyles from '../../common/button/button.module.scss'
import ProgressBar from 'react-bootstrap/ProgressBar'
import CsvInnerButton from './csvInnerButton'
import { CsvDownloadFormProps } from './types'
import { isEmptyObject } from '../../../utils/functions'
import { subDays } from 'date-fns'
import { formatExcelFileNameDate } from '../../../utils/datetime'

enum CsvActions {
   Filtered = 1,
   Full = 2,
}

const CsvDownloadForm = ({
   primaryActionText,
   secondaryActionText,
   closeModal,
   fileName,
   path,
   setIsDownloading,
   isDownloading,
   filters = {},
   defaultParams = { isExportForAll: true },
   buttonRef,
   query,
}: CsvDownloadFormProps) => {
   const [isDisabled, setIsDisabled] = useState<boolean>(true)
   const [params, setParams] = useState<any>({})
   const [downloadPercentage, setDownloadPercentage] = useState<number>(0)
   const [downloadComplete, setDownloadComplete] = useState<boolean>(false)

   const listParams = (action: CsvActions) => {
      setIsDisabled(false)

      if (action === CsvActions.Filtered) {
         setParams({ offset: -1, query, ...filters })
      } else {
         setParams(defaultParams)
      }
   }

   const handleCloseModal = () => {
      setDownloadComplete(false)
      setDownloadPercentage(0)
      closeModal()
      // @ts-ignore
      buttonRef.current.click()
   }

   useEffect(() => {
      if (downloadPercentage === 100) {
         setDownloadComplete(true)
      }
   }, [downloadPercentage])

   useEffect(() => {
      if (downloadComplete) {
         const timer = setTimeout(() => {
            handleCloseModal()
         }, 1500)
         return () => clearTimeout(timer)
      }
   }, [downloadComplete])

   const csvFileName = useMemo(() => {
      // display filter dates in file name for costing history
      if (
         fileName?.includes('CostingHistory') &&
         !params?.isExportForAll &&
         !isEmptyObject(params)
      ) {
         const itemsText = fileName.replace('.xlsx', '')
         const startDate = filters?.startDate
            ? `From ${formatExcelFileNameDate(filters.startDate)}`
            : `From ${formatExcelFileNameDate(subDays(new Date(), 1))}`
         const endDate = filters?.endDate
            ? `To ${formatExcelFileNameDate(filters.endDate)}`
            : `To ${formatExcelFileNameDate(new Date())}`
         return `${itemsText} - ${startDate} ${endDate}.xlsx`
      } else {
         return fileName
      }
   }, [fileName, params, filters])

   return (
      <>
         <RadioButtonContainer className={styles.radioButtons}>
            <RadioButton
               id="radio-1"
               groupName="radioGroup"
               getList={() => listParams(CsvActions.Filtered)}
               checked={false}
               disabled={isDownloading}
            >
               <CsvInnerButton
                  id="glass"
                  overrideViewBox="0,0,1,7"
                  text="Export filtered list"
                  height={50}
                  width={50}
               />
            </RadioButton>
            <div>
               <RadioButton
                  id="radio-2"
                  groupName="radioGroup"
                  getList={() => listParams(CsvActions.Full)}
                  checked={false}
                  disabled={isDownloading}
               >
                  <CsvInnerButton
                     id="export"
                     overrideViewBox="0,0,1,7"
                     text="Export all"
                     height={50}
                     width={50}
                  />
               </RadioButton>
               <RadioButton
                  id="radio-3"
                  groupName="radioGroup"
                  getList={() => setIsDisabled(true)}
                  buttonRef={buttonRef}
               >
                  Reset
               </RadioButton>
            </div>
         </RadioButtonContainer>
         {(isDownloading || downloadPercentage > 1) && (
            <div className={styles.progressWrapper}>
               <ProgressBar
                  now={downloadPercentage}
                  label={
                     <div className={styles.progressLabelWrapper}>
                        {downloadPercentage}%
                     </div>
                  }
                  animated={true}
                  variant="success"
                  style={{
                     width: '80%',
                     display: 'flex',
                     justifyContent: !downloadPercentage
                        ? 'center'
                        : 'flex-start',
                  }}
               >
                  {!downloadPercentage && (
                     <div className={styles.progressSpinnerWrapper}>
                        <Spinner className={buttonStyles.btnSpinner} /> &nbsp;
                        Downloading
                     </div>
                  )}
               </ProgressBar>
            </div>
         )}
         <div className={styles.buttonContainer}>
            <div className={styles.buttonItem}>
               <Button
                  onClick={null}
                  minWidth="0"
                  variant="success"
                  disabled={isDisabled || isDownloading}
                  icon={<Svg id="export-row" className={styles.excelIcon} />}
               >
                  {!isDisabled && (
                     <CsvAnchor
                        fileName={csvFileName}
                        text={primaryActionText}
                        path={path}
                        params={params}
                        setIsDownloading={setIsDownloading}
                        setDownloadPercentage={setDownloadPercentage}
                     />
                  )}
                  {isDisabled && primaryActionText}
               </Button>
            </div>
            <div className={styles.buttonItem}>
               <Button
                  onClick={handleCloseModal}
                  minWidth="0"
                  variant="tertiary"
               >
                  {secondaryActionText}
               </Button>
            </div>
         </div>
      </>
   )
}

export default memo(CsvDownloadForm)
