import React, { memo, useMemo } from 'react'

import * as styles from './styles.module.scss'
import { RateChangeProps } from './types'
import Dropdown from '../dropdown/dropdown'
import { Col, Row } from 'react-grid-system'
import Input from '../input/Input'
import { NumericFormat } from 'react-number-format'
import { rateChangeTypeOptions } from './constants'

const RateChange = ({
   id,
   label,
   value,
   onChange,
   disabled,
   error,
   noMargin,
   changeType,
   onChangeTypeChange,
}: RateChangeProps) => {
   const signBefore = useMemo(
      () =>
         changeType?.value === 'replace' ? (
            <span className={styles.dollarSign} />
         ) : (
            <></>
         ),
      [changeType]
   )

   const signAfter = useMemo(
      () =>
         changeType?.value === 'markup' || changeType?.value === 'markdown' ? (
            <span className={styles.percentageSign} />
         ) : (
            <></>
         ),
      [changeType]
   )

   return (
      <div className={`${styles.wrapper} ${noMargin ? styles.noMargin : ''}`}>
         <label
            htmlFor={id}
            className={`${styles.label} ${disabled ? styles.disabled : ''}`}
         >
            {label}
         </label>
         <Row gutterWidth={24}>
            <Col xs={6}>
               <Dropdown
                  id={`${id}ChangeType`}
                  name={`${id}ChangeType`}
                  placeholder="Type of Change"
                  options={rateChangeTypeOptions}
                  onChange={onChangeTypeChange}
                  value={changeType?.value}
                  defaultValue={changeType}
                  disabled={disabled}
                  isMulti={false}
                  isClearable={false}
                  isControlled
                  noMargin
               />
            </Col>
            <Col xs={6}>
               <div className={styles.numberInput}>
                  {signBefore}
                  <NumericFormat
                     customInput={Input}
                     id={id}
                     label=" "
                     disabled={disabled}
                     value={value}
                     onChange={onChange}
                     error={error}
                     decimalScale={2}
                     allowNegative={false}
                     maxLength="11"
                     noMargin
                  />
                  {signAfter}
               </div>
            </Col>
         </Row>
      </div>
   )
}

export default memo(RateChange)
