import React, { memo, useMemo } from 'react'
import { IoMdClose as NoIcon } from 'react-icons/io'
import { HiOutlineCheck as YesIcon } from 'react-icons/hi'

import * as styles from './trueOrFalse.module.scss'

const TrueOrFalse = ({ status }: TrueOrFalseProps) => {
   const title = useMemo(() => (status ? 'Yes' : 'No'), [status])
   return (
      <span className={styles.wrapper}>
         {status ? (
            <YesIcon className={styles.trueIcon} title={title} />
         ) : (
            <NoIcon className={styles.falseIcon} title={title} />
         )}
      </span>
   )
}
export default memo(TrueOrFalse)
