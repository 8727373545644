import { useState } from 'react'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'

import { useAPI } from '..'
import { PATH } from '../../constants/global'

import { UseForgottenPassword } from './useForgottenPassword.type'
import { APIError } from '../types'
import { getAPIErrorMessage } from '../../utils/functions'

export default function (): UseForgottenPassword {
   const request = useAPI()
   const [error, setErrors] = useState('')
   const { isLoading, isSuccess, mutate } = useMutation(
      ({ email, token }: { email: string; token: string }) => {
         return request.post(PATH.AUTH.FORGOTTEN_PASSWORD, { email, token })
      },
      {
         onError: (error: APIError) => {
            const msg = getAPIErrorMessage(error)
            setErrors(msg)
         },
      }
   )

   const submit = (email: string, token: string) => {
      // Pass an object with email and token properties to the mutation function
      mutate({ email, token })
   }

   return {
      error,
      isLoading,
      isSuccess,
      submit,
   }
}
