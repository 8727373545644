import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import {
   ImageMatchPayload,
   UploadMatchedImagesPayload,
   UseImageMatch,
} from './types'
import { useEffect, useState } from 'react'
import { APIError } from '../types'
import { getAPIErrorMessage } from '../../utils/functions'

export default function (): UseImageMatch {
   const request = useAPI()
   const [error, setError] = useState<string | null>(null)

   const {
      data,
      isError: isImageMatchError,
      isLoading: isImageMatchLoading,
      isSuccess: isImageMatchSuccess,
      mutate,
   } = useMutation(getImagesFromAPI, {
      onError: (error: APIError) => {
         const msg = getAPIErrorMessage(error)
         setError(msg)
      },
   })

   async function getImagesFromAPI(
      payload: ImageMatchPayload
   ): Promise<AxiosResponse> {
      return request.post(PATH.IMAGES.IMAGE_MATCH, payload)
   }

   const imageMatch = (options: ImageMatchPayload) => mutate(options)

   const {
      isError: isUploadImagesError,
      isLoading: isUploadImagesLoading,
      isSuccess: isUploadImagesSuccess,
      mutate: uploadMutate,
   } = useMutation(addMatchedImagesToItem, {
      onError: (error: APIError) => {
         const msg = getAPIErrorMessage(error)
         setError(msg)
      },
   })

   async function addMatchedImagesToItem(
      payload: UploadMatchedImagesPayload
   ): Promise<AxiosResponse> {
      return request.post(PATH.IMAGES.SINGLE_ITEM_UPLOAD, payload)
   }

   const uploadMatchedImages = (payload: UploadMatchedImagesPayload) =>
      uploadMutate(payload)

   useEffect(() => {
      if (
         (!isImageMatchError && !isUploadImagesError) ||
         isImageMatchLoading ||
         isUploadImagesLoading
      ) {
         setError(null)
      }
   }, [
      isImageMatchError,
      isUploadImagesError,
      isImageMatchLoading,
      isUploadImagesLoading,
   ])

   return {
      imageMatchError: error,
      imageMatchData: data?.data,
      imageMatch,
      isImageMatchError,
      isImageMatchLoading,
      isImageMatchSuccess,
      uploadMatchedImages,
      isUploadImagesLoading,
      isUploadImagesSuccess,
      isUploadImagesError,
      uploadImagesError: error,
   }
}
