import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { AxiosResponse, AxiosError } from 'axios'

import { useAPI } from '..'
import { UseWorkflow } from './useWorkflow.type'
import { getAPIErrorMessage } from '../../utils/functions'
import { APIError } from '../types'

export default function (): UseWorkflow {
   const request = useAPI()
   const [isWorkflowError, setIsWorkflowError] = useState<boolean>(false)
   const [workflowError, setWorkflowError] = useState<string>('')
   const [path, setPath] = useState<string>('')
   const [workflowRequestOptions, setWorkflowRequestOptions] = useState<
      object | null
   >(null)
   const [workflowResponse, setWorkflowResponse] = useState<any>(null)

   useEffect(() => {
      if (path && workflowRequestOptions) {
         updateLoanBorrowRequest(workflowRequestOptions)
      }
   }, [path, workflowRequestOptions])

   const {
      isLoading: isPostingRequest,
      isSuccess: isWorkflowPostSuccess,
      mutate: updateLoanBorrowRequest,
   } = useMutation(initiateWorkflowRequest, {
      onSuccess: (data) => {
         const { Data } = data?.data
         const hasError = Data?.Failed?.length > 0
         setIsWorkflowError(hasError)
         setWorkflowResponse(Data)
         setWorkflowError(
            hasError ? 'One or more of the workflow requests failed' : ''
         )
         setPath('')
      },
      onError: (error: APIError) => {
         setIsWorkflowError(true)
         const msg = getAPIErrorMessage(error)
         setWorkflowError(msg)
      },
   })

   async function initiateWorkflowRequest(
      workflowRequestOptions: any
   ): Promise<AxiosResponse> {
      return await request.post(path, workflowRequestOptions)
   }

   const postWorkflowRequest = (path: string, workflowOptions: any) => {
      setIsWorkflowError(false)
      setWorkflowError('')
      setPath(path)
      setWorkflowRequestOptions(workflowOptions)
   }

   return {
      workflowResponse: workflowResponse,
      isPostingRequest,
      postWorkflowRequest,
      isWorkflowPostSuccess,
      isWorkflowError,
      workflowError,
   }
}
