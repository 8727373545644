import { RateChangeTypeOption } from './types'

const rateChangeTypeOptions: RateChangeTypeOption[] = [
   {
      value: 'replace',
      label: 'Replace',
   },
   {
      value: 'markup',
      label: 'Mark Up \u2191',
   },
   {
      value: 'markdown',
      label: 'Mark Down \u2193',
   },
]

export { rateChangeTypeOptions }
