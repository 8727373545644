import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { SearchOptions, UseMassEditItems } from './types'
import { APIError } from '../types'
import { getAPIErrorMessage } from '../../utils/functions'

export default function (): UseMassEditItems {
   const request = useAPI()
   const [searchOptions, setSearchOptions] = useState<SearchOptions | null>(
      null
   )
   const [error, setError] = useState<string | null>(null)

   const {
      data,
      refetch,
      isLoading: itemsIsLoading,
      isRefetching: itemsIsRefetching,
   } = useQuery(
      'mass-edit-items',
      (): Promise<AxiosResponse> =>
         getMassEditItems({
            ...searchOptions,
            isForMultiselectFilter: true,
         }),
      {
         enabled: !!searchOptions,
         onError: (error: APIError) => {
            const msg = getAPIErrorMessage(error)
            setError(msg)
         },
      }
   )

   async function getMassEditItems(options: SearchOptions) {
      return await request.post(PATH.ITEMS.MASS_EDIT_ITEMS, options)
   }

   const {
      data: countData,
      refetch: refetchCount,
      isLoading: countIsLoading,
      isRefetching: countIsRefetching,
   } = useQuery(
      'mass-edit-items-count',
      (): Promise<AxiosResponse> =>
         getActionItemsCount({
            ...searchOptions,
            isForMultiselectFilter: true,
         }),
      {
         enabled: !!searchOptions,
         onError: (error: APIError) => {
            const msg = getAPIErrorMessage(error)
            setError(msg)
         },
      }
   )

   async function getActionItemsCount(options: SearchOptions) {
      return await request.post(PATH.ITEMS.MASS_EDIT_ITEMS_COUNT, options)
   }

   useEffect(() => {
      if (searchOptions) {
         refetch()
         refetchCount()
      }
   }, [searchOptions])

   return {
      massEditItems: data?.data ?? [],
      massEditItemsCount: countData?.data?.Count,
      isLoading: itemsIsLoading || countIsLoading,
      isRefetching: itemsIsRefetching || countIsRefetching,
      error,
      setSearchOptions,
   }
}
