import React, { memo, useCallback, useMemo } from 'react'
import * as styles from './addToCartButton.module.scss'

import { AddToCartButtonProps } from './actionsWorkflow.type'
import { Svg } from '../common'

const AddToCartButton = ({
   action,
   cell,
   isHidden,
   isItemInCart,
}: AddToCartButtonProps) => {
   const handleClick = useCallback(() => action(cell), [action, cell])

   const title = useMemo(
      () => (isItemInCart ? 'Remove from cart' : 'Add to cart'),
      [isItemInCart]
   )

   const iconId = useMemo(
      () => (isItemInCart ? 'removeFromCart' : 'addToCart'),
      [isItemInCart]
   )

   return isHidden ? (
      <></>
   ) : (
      <button
         className={`icon-button icon-addToCart ${styles.cartButton}`}
         onClick={handleClick}
         disabled={false}
         type="button"
         id={iconId}
         title={title}
      >
         <Svg id={iconId} />
      </button>
   )
}

export default memo(AddToCartButton)
