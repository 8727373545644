import { format, parse } from 'date-fns'

const FORMATS = {
   filterDateAPI: 'd MMM yyyy',
   filterDateUI: 'MM/dd/yyyy',
   excelExport: 'yyyy-MM-dd',
}

const renderDateOrNothing = (date: any) => {
   if (!date.value) {
      return '-'
   }
   return format(new Date(date.value), FORMATS.filterDateUI)
}

const formatLocaleDate = (
   dateString: string | Date,
   locale: string = 'en-GB'
) => {
   const dateOptions: any = { day: 'numeric', month: 'short', year: 'numeric' }
   if (
      new Date(dateString).toLocaleDateString(locale, dateOptions) ===
      'Invalid Date'
   ) {
      return null
   } else {
      return new Date(dateString).toLocaleDateString(locale, dateOptions)
   }
}

const formatUsDate = (d: string | Date) => {
   const formattedDate = new Date(d).toLocaleString('en-US', { hour12: false })
   return formattedDate.substring(0, formattedDate.indexOf(', '))
}

const removeMinYear = (value: string, minYear: number = 1970) => {
   const parts = value.split('/')
   const yearInt = parseInt(parts[parts.length - 1])
   if (yearInt <= minYear || yearInt === 2099) {
      return '-'
   }
   return value
}

const parseDate = (d: Date | string) => {
   // 1. Convert stored date to local timezone
   const localDate = new Date(d)
   // 2. Convert local date to UTC date using offset
   const utcDate = new Date(
      localDate.getTime() + localDate.getTimezoneOffset() * 60000
   )
   // 3. Return timestamp of UTC date
   return Date.parse(utcDate.toISOString())
}

const formatDateForTable = (rawDate?: string) => {
   return !rawDate || rawDate === '' || isNaN(parseInt(rawDate))
      ? '-'
      : removeMinYear(formatUsDate(rawDate))
}

const formatFilterDateForAPI = (date: Date) =>
   format(date, FORMATS.filterDateAPI)

const formatFilterDateForTag = (date: Date) =>
   format(date, FORMATS.filterDateUI)

const formatExcelFileNameDate = (d: string | Date) => {
   const parsedDate =
      typeof d === 'string' ? parse(d, 'd MMM yyyy', new Date()) : d
   return format(parsedDate, FORMATS.excelExport)
}

export {
   FORMATS,
   renderDateOrNothing,
   formatLocaleDate,
   parseDate,
   formatUsDate,
   removeMinYear,
   formatDateForTable,
   formatFilterDateForAPI,
   formatFilterDateForTag,
   formatExcelFileNameDate,
}
