import React, {
   memo,
   useCallback,
   useEffect,
   useMemo,
   useRef,
   useState,
} from 'react'
import {
   DragDropContext,
   Draggable,
   Droppable,
   DropResult,
} from 'react-beautiful-dnd'

import { IconButton } from '..'
import { Dropdown } from '../form/fields'
import { Option } from '../form/fields/dropdown/types'
import { useSettings } from '../../../hooks'

import { permissions } from '../columnReorderPanel/constants'

import * as styles from './itemReorder.module.scss'

const ItemReorder = ({ initialItems, items, onChange }: ItemReorderProps) => {
   const dropdownRef = useRef(null)
   const [itemList, setItemList] = useState<ReorderItem[]>(items)
   const [selectedItem, setSelectedItem] = useState<Option | null>(null)

   const { settings } = useSettings()

   useEffect(() => {
      if (itemList?.length > 0) {
         const itemsToPass = itemList.map((item) => item.value)
         onChange(itemsToPass)
      }
   }, [itemList])

   useEffect(() => {
      if (items?.length > 0) {
         const filteredList = items.filter((item) =>
            !permissions.hasOwnProperty(item.value)
               ? true
               : !!settings[permissions[item.value]]
         )
         setItemList(filteredList)
      }
   }, [items, permissions])

   const handleDrop = useCallback(
      (droppedItem: DropResult) => {
         // Ignore drop outside droppable container
         if (!droppedItem.destination) return

         let updatedList = [...itemList]
         // Remove dragged item
         const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)

         // Add dropped item
         updatedList.splice(droppedItem.destination.index, 0, reorderedItem)

         // Update State
         setItemList(updatedList)
      },
      [itemList]
   )

   const handleDelete = useCallback(
      (itemKey: string) =>
         setItemList((prevItems) =>
            prevItems.filter((item) => item.value !== itemKey)
         ),
      [itemList]
   )

   const handleAddColumn = useCallback(() => {
      setItemList((prevItems) => [selectedItem, ...prevItems])
      setSelectedItem(null)

      if (dropdownRef?.current?.Select) {
         dropdownRef.current.Select?.clearValue()
      }
   }, [itemList, selectedItem])

   const handleSelectColumn = useCallback((option: Option) => {
      setSelectedItem(option)
   }, [])

   const addDropdownOptions = useMemo(
      () =>
         initialItems.reduce((options, item) => {
            if (
               permissions.hasOwnProperty(item.value) &&
               !settings[permissions[item.value]]
            ) {
               return options
            }

            return itemList.find((i) => i.value === item.value)
               ? options
               : [...options, { label: item.label, value: item.value }]
         }, [] as ReorderItem[]),
      [initialItems, itemList]
   )

   const noOptionsAvailable = useMemo(
      () => addDropdownOptions?.length === 0,
      [addDropdownOptions]
   )

   const placeholder = useMemo(
      () => (noOptionsAvailable ? 'No columns available' : 'Add column'),
      [noOptionsAvailable]
   )

   return (
      <div>
         <div className={styles.addWrapper}>
            <div className={styles.addField}>
               <Dropdown
                  id="add-column"
                  name="add-column"
                  onChange={handleSelectColumn}
                  options={addDropdownOptions}
                  defaultValue={[selectedItem]}
                  placeholder={placeholder}
                  disabled={noOptionsAvailable}
                  ref={dropdownRef}
               />
            </div>
            <div className={styles.addBtn}>
               <IconButton
                  id="plus"
                  disabled={!selectedItem}
                  action={handleAddColumn}
               />
            </div>
         </div>

         <hr />

         <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="list">
               {({ droppableProps, innerRef, placeholder }) => (
                  <div className="list" {...droppableProps} ref={innerRef}>
                     {itemList?.length > 0 &&
                        itemList.map((item, index) => (
                           <Draggable
                              key={item.value}
                              draggableId={item.value}
                              index={index}
                           >
                              {({
                                 innerRef,
                                 dragHandleProps,
                                 draggableProps,
                              }) => (
                                 <div
                                    className={styles.item}
                                    ref={innerRef}
                                    {...dragHandleProps}
                                    {...draggableProps}
                                 >
                                    <span className={styles.label}>
                                       {item.label}
                                    </span>
                                    <span className={styles.deleteBtn}>
                                       <IconButton
                                          id="delete"
                                          action={() =>
                                             handleDelete(item.value)
                                          }
                                       />
                                    </span>
                                 </div>
                              )}
                           </Draggable>
                        ))}
                     {placeholder}
                  </div>
               )}
            </Droppable>
         </DragDropContext>
      </div>
   )
}

export default memo(ItemReorder)
