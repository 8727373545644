import { formatUsDate, removeMinYear } from '../../../utils/datetime'
import { Item, ToolTypes } from '../../../types'
import { Settings } from '../../../hooks/useSettings/useSettings.type'

export const generateExcelData = (items: Item[], settings: Settings) => {
   const excelData = items.map((item) => {
      return {
         'Item Name': item.Title,
         Type: ToolTypes[item.ToolType],
         'Assigned to Kit': item.AssignedToKitName,
         'Total Qty': item.Quantity,
         'Reorder Level': item.ReorderLevelQuantity,
         'Status Qty': item.StatusQuantity,
         Status: item.StatusDesc,
         'Loaned/Pending To': item.AssignedToUser,
         'Item Detail': item.Description,
         Manufacturer: item.Manufacturer,
         'Model No.': item.ModelNumber,
         'Serial No.': item.SerialNumber,
         Barcode: item.Barcode,
         Category: item.Category,
         'Purchase Date': removeMinYear(formatUsDate(item.PurchaseDate)),
         'Purchase Price': item.PurchasePrice,
         'Total Value': item.TotalValue,
         'Warranty Date': removeMinYear(formatUsDate(item.WarrantyDate)),
         'Rental Item': item.RentalDate ? 'rented' : 'not rented',
         'Rental Start Date': removeMinYear(formatUsDate(item.RentalDate)),
         'Rental End Date': removeMinYear(formatUsDate(item.RentalReturnDate)),
         [settings.CustomRentalRate1Label]: settings.CustomRentalRate1Label
            ? item.CustomRentalRate1
            : '-',
         [settings.CustomRentalRate2Label]: settings.CustomRentalRate2Label
            ? item.CustomRentalRate2
            : '-',
         [settings.CustomRentalRate3Label]: settings.CustomRentalRate3Label
            ? item.CustomRentalRate3
            : '-',
         [settings.CustomRentalRate4Label]: settings.CustomRentalRate4Label
            ? item.CustomRentalRate4
            : '-',
         [settings.CustomDate1Label]: settings.CustomDate1Label
            ? removeMinYear(formatUsDate(item.CustomDate1Value))
            : '-',
         [settings.CustomDate2Label]: settings.CustomDate2Label
            ? removeMinYear(formatUsDate(item.CustomDate2Value))
            : '-',
         [settings.CustomText1Label]: settings.CustomText1Label
            ? item.CustomText1Value
            : '-',
         [settings.CustomText2Label]: settings.CustomText2Label
            ? item.CustomText2Value
            : '-',
         [settings.CustomURL1Label]: settings.CustomURL1Label
            ? item.CustomURL1Value
            : '-',
         [settings.CustomURL2Label]: settings.CustomURL2Label
            ? item.CustomURL2Value
            : '-',
         'WebSite URL': item.WebSiteURL,
         'Owners URL': item.AttachmentUrl,
         'Return Warehouse': item.DefaultLocation,
         'Current/Pending Location': item.CurrentLocation,
         'Last Audit Date': removeMinYear(formatUsDate(item.LastAuditDate)),
         'Last Audit Condition': item.LastAuditConditionDesc,
         Vendor: item.Vendor,
         'Created Date': removeMinYear(formatUsDate(item.CreatedDate)),
         'Item ID': item.ItemId,
      }
   })

   return excelData
}
