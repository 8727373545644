import React, {
   Children,
   forwardRef,
   LegacyRef,
   memo,
   useCallback,
   useMemo,
   useRef,
} from 'react'
import Select, { components, createFilter } from 'react-select'

import { FieldWrapper } from '..'

import { DropdownProps } from './types'
import styles from './dropdown.styles'
import multiSelectStyles from './dropdownMultSelectStyles.styles'

const Dropdown = forwardRef(
   (
      {
         className,
         defaultValue,
         description,
         insetDescription = false,
         disabled = false,
         error,
         isClearable = true,
         isLoading,
         isMulti,
         id,
         name,
         noMargin = false,
         onChange,
         onTextChange,
         options,
         required,
         withWrappers = true,
         menuPosition = null,
         maxMenuHeight = 215,
         minMenuHeight = 215,
         placeholder,
         excludeOptionValueFromSearch = false,
         menuPlacement = 'bottom',
      }: DropdownProps,
      ref
   ): JSX.Element => {
      const inputRef = useRef()

      const elRef: LegacyRef<any> = useMemo(() => ref || inputRef, [ref])

      // custom value container allows for a persistent placeholder on select
      const CustomValueContainer = ({ children, ...props }: any) => {
         const { ValueContainer, Placeholder } = components
         return (
            <ValueContainer {...props}>
               <Placeholder {...props} isFocused={props.isFocused}>
                  {props.selectProps.placeholder}
               </Placeholder>
               {Children.map(children, (c) =>
                  c && c.key !== 'placeholder' ? c : null
               )}
            </ValueContainer>
         )
      }

      const filterOption = useMemo(
         () =>
            createFilter({
               matchFrom: 'any',
               stringify: (option) =>
                  // stops the search from filtering on ID
                  excludeOptionValueFromSearch
                     ? `${option.label}`
                     : `${option.value} ${option.label}`,
            }),
         [excludeOptionValueFromSearch, createFilter]
      )

      const onBlur = useCallback(
         (e) => (onTextChange ? onTextChange(e.target.value, name) : null),
         [onTextChange, name]
      )

      const selectStyles = useMemo(
         () => (isMulti ? multiSelectStyles : styles),
         [isMulti]
      )

      const placeholderText = useMemo(
         () => (required ? `${placeholder} *` : placeholder),
         [required, placeholder]
      )

      const childComponents = useMemo(
         () =>
            !isMulti
               ? {
                    ValueContainer: CustomValueContainer,
                 }
               : null,
         [isMulti, CustomValueContainer]
      )

      return (
         <>
            {withWrappers ? (
               <FieldWrapper
                  description={description}
                  insetDescription={insetDescription}
                  disabled={disabled}
                  error={error}
                  id={id}
                  required={required}
                  noMargin={noMargin}
               >
                  <Select
                     filterOption={filterOption}
                     className={className}
                     isClearable={isClearable}
                     isLoading={isLoading}
                     isMulti={isMulti}
                     name={name}
                     onChange={onChange}
                     onBlur={onBlur}
                     options={options}
                     ref={elRef}
                     styles={selectStyles}
                     value={defaultValue}
                     placeholder={placeholderText}
                     isDisabled={disabled}
                     menuPosition={menuPosition}
                     menuPlacement={menuPlacement}
                     maxMenuHeight={maxMenuHeight}
                     minMenuHeight={minMenuHeight}
                     components={childComponents}
                  />
               </FieldWrapper>
            ) : (
               <Select
                  filterOption={filterOption}
                  className={className}
                  isClearable={isClearable}
                  isLoading={isLoading}
                  isMulti={isMulti}
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  options={options}
                  ref={elRef}
                  styles={selectStyles}
                  value={defaultValue}
                  placeholder={placeholderText}
                  isDisabled={disabled}
                  menuPosition={menuPosition}
                  menuPlacement={menuPlacement}
                  maxMenuHeight={maxMenuHeight}
                  minMenuHeight={minMenuHeight}
                  components={childComponents}
               />
            )}
         </>
      )
   }
)

export default memo(Dropdown)
