import { useMutation } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { useEffect, useState } from 'react'
import { APIError } from '../types'
import { getAPIErrorMessage } from '../../utils/functions'

export default (): UseMassEdit => {
   const request = useAPI()
   const [error, setError] = useState<string | null>(null)

   const { mutate, data, isLoading, isSuccess, isError } = useMutation(
      'mass-edit-items',
      massEditInAPI,
      {
         onError: (error: APIError) => {
            const msg = getAPIErrorMessage(error)
            setError(msg)
         },
      }
   )

   async function massEditInAPI(
      payload: MassEditPayload
   ): Promise<AxiosResponse<MassEditResponse>> {
      return await request.post(PATH.ITEMS.MASS_EDIT, payload)
   }

   const massEditItems = (payload: MassEditPayload) => mutate(payload)

   useEffect(() => {
      if (!isError || isLoading) {
         setError(null)
      }
   }, [isError, isLoading])

   return {
      updatedItems: data?.data ?? [],
      massEditItems,
      isLoading,
      isSuccess,
      isError,
      error,
   }
}
